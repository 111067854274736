<template>
    <v-card
        elevation="2"
    >
        <v-card-title>Quiénes somos</v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-textarea
                        outlined
                        name="input-7-4"
                        label="Descripción Quiénes somos"
                        v-model="About.description"
                    ></v-textarea>
                </v-col>
                <v-col>
                    <v-file-input
                        accept="image/*"
                        label="Imagen de portada"
                        @change="onAboutImage"
                        outlined
                        dense
                    ></v-file-input>
                    <v-file-input
                        label="Video introductorio"
                        @change="onAboutVideo"
                        outlined
                        dense
                    ></v-file-input>
                </v-col>
            </v-row>
            
            <h2>Texto informativo</h2>
            <br>
            <quill-editor
                ref="myQuillEditor"
                v-model="About.information_text"
                :options="editorOption"
            />
        </v-card-text>
        <v-card-actions>
            <v-btn
                elevation="2"
                small
                color="primary"
                @click="saveAbout"
            >
                Guardar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import {misMixins} from '../../../vue/mixinsAlert'; 
  export default {
    data () {
      return {
        About: {
            description: null,
            information_text: '',
            img: null,
            townhall: null,
            video: null
        },
        editorOption: {
            
        }
      }
    },

    mixins:[misMixins],

    methods: {
        saveAbout(){

            this.LoadingOpen();

            this.$http.post('townhalls/saveAbout', this.About,{headers:{"Authorization": 'Bearer '+this.getCrypto()}}).then(response => {
                console.log('Save about:', response)
                this.LoadingClose();

                        this.SucessAlert(
                        'Creado',
                        'about creado con éxito'
                        )
                this.getAbout();
            }).catch(error => {
                console.log('Error al guardar About', error)
                this.LoadingClose();
                this.ErrorAlert('Error al crear la about')
            })
        },
        getAbout(){
            this.$http.get('townhalls/getAbout/'+this.getClientSelect().id).then(response => {
                this.About = {
                    description: response.body.about.description,
                    information_text: response.body.about.information_text,
                    img: null,
                    townhall: response.body.about.townhall_id
                }
            }).catch(error => {
                console.log('Error al consultar About', error)
                this.ToastError(error.data.status +' '+error.data.error)
            })
        },
        onAboutImage(img){
            var reader = new FileReader();
            var vm = this;

            reader.readAsDataURL(img);
            reader.onload = function () {
                vm.About.img = reader.result
            }
        },
        onAboutVideo(video){
            var reader = new FileReader();
            var vm = this;

            reader.readAsDataURL(video);
            reader.onload = function () {
                vm.About.video = reader.result
            }
        },
    },
    created() {
        this.getAbout()
    },
  }
</script>